import React from 'react'
import classNames from 'classnames'

import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

class Cookies extends React.Component {
  render() {
    return (
      <Page
        className={classNames('legal', 'cookies', this.props.className)}
        headerTitle="Live365 Broadcaster LLC (“Live365”) Cookie Policy"
        headerSubtitle="Effective as of 25 May 2018"
      >


        <PageSection>
          <p>
            Hello, and welcome to Live365’s Cookie Policy (“Policy”). The
            objective of this Policy is to provide you, as a user of Live365’s
            services, with clear and accessible information about the cookies
            that Live365 uses, the role they play in helping us provide the best
            possible experience to you and the choices you have when it comes to
            your cookie settings.
          </p>
        </PageSection>

        <PageSection>
          <h3>1. What are cookies and other similar technologies?</h3>
          <p>
            Cookies are text files which are downloaded to your device when you
            visit a website. They are useful because they allow a website to
            recognize a user’s device. You can find more information about
            cookies at:{' '}
            <a
              href="www.allaboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.allaboutcookies.org
            </a>
            .
          </p>
          <p>
            The term cookie actually describes a range of technologies,
            including:
          </p>
          <ul>
            <li>
              pixel tags (transparent graphic images placed on a web page or in
              an email, which indicate that a page or email has been viewed),
            </li>
            <li>mobile device identifiers, and</li>
            <li>web storage used in desktop software or mobile devices.</li>
          </ul>{' '}
          <p>
            We’ll use the term cookie throughout this Policy to cover all of
            these technologies, but we’ll make sure we provide you with clear
            details about them all so that you can make informed choices about
            your cookie settings.
          </p>
          <p>
            Cookies do lots of different jobs, like letting you navigate between
            pages efficiently, remembering your preferences, and generally
            improving your user experience. They can also help to ensure that
            ads you see online are more relevant to you and your interests.
          </p>
          <p>There are a few different categories of cookies, including:</p>
          <h4>Session and Persistent Cookies</h4>
          <p>
            Session Cookies - these are cookies which expire once you close your
            web browser; and
          </p>
          <p>
            Persistent Cookies - these are cookies which stay on your device for
            a set period of time or until you delete them.
          </p>
          <h4>First and Third Party Cookies</h4>
          <p>
            First-party cookies - these are cookies set by the website that you
            are visiting at that time, either by us, or by a third party at our
            request;
          </p>
          <p>
            Third-party cookies - these are cookies that are set by a party
            other than that of the website you are visiting. If you visit the
            Live365 website or use the Live365 Service and another party sets a
            cookie through that website this would be a third-party cookie.
          </p>
          <p>
            Cookies also differ depending on the purpose they are used for,
            including:
          </p>
          <table>
            <tr>
              <th>Cookie Type</th>
              <th>Purpose</th>
            </tr>
            <tr>
              <td>Strictly Necessary Cookies</td>
              <td>
                <p>
                  These cookies are essential in order to enable you to use the
                  features of our services, such as maintaining your playlist.
                  Without these cookies, services you have asked for e.g.,
                  e-billing, cannot be provided.  In summary, these cookies
                  enable services you have specifically asked for and make using
                  our services possible.
                </p>
              </td>
            </tr>
            <tr>
              <td>Performance Cookies</td>
              <td>
                <p>
                  These cookies collect information about how visitors use our
                  services, for instance which pages visitors go to most often,
                  and if they get error messages from web pages. These cookies
                  collect anonymous information on the pages visited. All
                  information these cookies collect is aggregated and therefore
                  anonymous. It is only used to improve how our services
                  perform.  Web analytics that use cookies to gather data to
                  enhance the performance of a website fall into this category.
                  For example, they may be used for testing designs and ensuring
                  a consistent look and feel is maintained for the user. This
                  category does not include cookies used for behavioral/targeted
                  advertising networks.
                </p>
              </td>
            </tr>
            <tr>
              <td>Functionality Cookies</td>
              <td>
                <p>
                  These cookies allow the website to remember choices you make
                  (such as your user name, language or the region you are in)
                  and provide enhanced, more personal features. These cookies
                  can also be used to remember changes you have made to text
                  size, fonts and other parts of web pages that you can
                  customize. The information these cookies collect may be
                  anonymized and they cannot track your browsing activity on
                  other websites.  These cookies remember choices you make to
                  improve your experience.  If the same cookie is used for
                  re-targeting they must be included in the ‘Targeting or
                  advertising cookies’ category as well. It might also include
                  cookies that are used to deliver a specific function, but
                  where that function includes cookies used for
                  behavioral/targeted advertising networks they must be included
                  in the ‘Targeting or advertising cookies’ category as well as
                  this category.
                </p>
              </td>
            </tr>
            <tr>
              <td>Targeting or Advertising Cookies</td>
              <td>
                <p>
                  These cookies are used to deliver advertisements more relevant
                  to you and your interests. They are also used to limit the
                  number of times you see an advertisement as well as help
                  measure the effectiveness of the advertising campaigns. They
                  are usually placed by advertising networks with the website
                  operator’s permission. They remember that you have visited a
                  website and this information is shared with other
                  organizations such as advertisers. Quite often targeting or
                  advertising cookies will be linked to site functionality
                  provided by the other organizations.  These cookies collect
                  information about your browsing habits in order to make
                  advertising relevant to you and your interests.
                </p>
              </td>
            </tr>
          </table>
        </PageSection>

        <PageSection>
          <h3>2. How does Live365 use cookies?</h3>

          <p>
            Live365, or our service providers acting on our behalf, uses the
            types of cookies identified in Section 1 above in the following
            ways:
          </p>

          <table>
            <tr>
              <th>Cookie Type</th>
              <th>Purpose</th>
            </tr>
            <tr>
              <td>Strictly Necessary</td>
              <td>
                <p>
                  These cookies are necessary to allow us to operate the Live365
                  Service as you have requested. For example, they let us
                  recognize what type of subscriber you are and then provide you
                  with services accordingly.
                </p>
              </td>
            </tr>
            <tr>
              <td>Performance/Analytics</td>
              <td>
                <p>
                  We use cookies and other similar technologies to analyze how
                  the Live365 Service is accessed, is used, or is performing. We
                  use this information to maintain, operate, and continually
                  improve the Live365 Service. We may also obtain information
                  from our email newsletters or other communications we send to
                  you, including whether you opened or forwarded a newsletter or
                  clicked on any of its content. This information tells us about
                  our newsletters' effectiveness and helps us ensure that we're
                  delivering information that you find interesting.
                </p>
              </td>
            </tr>
            <tr>
              <td>Functional</td>
              <td>
                <p>
                  These cookies let us operate the Live365 Service according to
                  your preferences. For example, when you continue to use or
                  come back to the Live365 Service, we can provide you with our
                  services based on information you provide to us, such as
                  remembering your username, how you have customized our
                  services, and reminding you of content you have enjoyed or
                  listened to on the service previously.
                </p>
              </td>
            </tr>
            <tr>
              <td>Targeted Advertising</td>
              <td>
                <p>
                  We use these cookies and other similar technologies to serve
                  you with advertisements that may be relevant to you and your
                  interests, including interest-based advertising. The
                  information may also be used to record how many times you’ve
                  been served a particular advertisement and to ensure we do not
                  display the same advertisement to you repeatedly, and to
                  otherwise help us measure their effectiveness.
                </p>
              </td>
            </tr>
            <tr>
              <td>Third-party</td>
              <td>
                <p>
                  We may allow our business partners to use cookies or other
                  similar technologies on or outside the Live365 Service for the
                  same purposes identified above, including collecting
                  information about your online activities over time and across
                  different websites, applications, and/or devices.
                </p>
              </td>
            </tr>
            <tr>
              <td>Live365 Ads</td>
              <td>
                <p>
                  We work with website publishers, application developers,
                  advertising networks, and service providers to deliver
                  advertisements and other content promoting Live365 on other
                  web sites and services. Cookies and other similar technologies
                  may be used to serve you with advertisements that may be
                  relevant to you and your interests on other websites,
                  applications, and devices, and to regulate the advertisements
                  you receive and measure their effectiveness.
                </p>
              </td>
            </tr>
          </table>
        </PageSection>

        <PageSection>
          <h3>3. How to manage your cookie preferences</h3>

          <h4>Browser Cookies</h4>
          <p>
            You can withdraw or modify your consent to our use of cookies at any
            time. If you no longer wish to receive cookies you can use your web
            browser settings to accept, refuse and delete cookies. To do this,
            follow the instructions provided by your browser (usually located
            within the “Help”, “Tools” or “Edit” settings). Please note that if
            you set your browser to refuse cookies, you may not be able to use
            all the features of the Live365 website. To find out more
            information, you can visit{' '}
            <a
              href="www.allaboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.allaboutcookies.org
            </a>
            .
          </p>
          <h4>Interest-Based Advertising</h4>
          <p>
            Certain targeted advertisements that we (or a service provider
            acting on our behalf) display to you based on information about your
            online activities across websites and other interactive properties
            operated by third parties may include the “Ad Choices” icon or
            another mechanism to opt out of receiving interest-based
            advertisements. You may click on the AdChoices icon or visit{' '}
            <a
              href="www.aboutads.info"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.aboutads.info
            </a>
            to receive more information about the collection and use of
            information about your online activities for interest based
            advertising or to learn how to opt out of having your data used for
            interest based advertising by Digital Advertising Alliance (DAA)
            participating companies.
          </p>
          <p>
            <ul>
              <li>
                Canadian users can also visit{' '}
                <a
                  href="www.youradchoices.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.youradchoices.ca
                </a>
                .
              </li>
              <li>
                European users can also visit{' '}
                <a
                  href="www.youronlinechoices.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.youronlinechoices.com
                </a>
                to learn how to opt out of having their data used for
                interest-based advertising by European Interactive Digital
                Advertising Alliance (EDAA) member companies.
              </li>
              <li>
                The Network Advertising Initiative’s (NAI) Opt-Out Tool at{' '}
                <a
                  href="www.networkadvertising.org/choices"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.networkadvertising.org/choices
                </a>
                allows you to opt out from the use of information about your
                online activities for interest based advertising by NAI member
                companies.
              </li>
            </ul>
          </p>
          <p>
            Please note that even if you opt out using the mechanisms above, you
            may still receive advertisements when using the Live365 Service.
          </p>
        </PageSection>

        <PageSection>
          <h3>4. Updates to this Policy</h3>

          <p>We may occasionally make changes to this Policy.</p>
          <p>
            When we make material changes to this Policy, we’ll provide you with
            prominent notice e.g., by displaying a prominent notice within the
            Live365 Service or by sending you an email. We may notify you in
            advance.
          </p>
        </PageSection>

        <PageSection>
          <h3>5. How to Contact Us</h3>

          <p>
            Thank you for reading our Cookie Policy. If you have any questions
            about this Cookie Policy, please contact us.
          </p>

          <address>
            <span className="address-line">Live365</span>
            <span className="address-line">1340 Monticello Avenue</span>
            <span className="address-line">Hermitage, PA 16148</span>
            <span className="address-line">privacy@live365.com</span>
          </address>
        </PageSection>
      </Page>
    )
  }
}

export default Cookies
